<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{ ('Edit User') }}</h2>
    <FormUsers :baseUser="user" @submit-requested="submitRequested"></FormUsers>
  </div>
</template>
 
<script>
import { mapState, mapActions } from 'vuex'
import FormUsers from '../forms/FormUsers.vue'
import router from '@/router';

  
  export default {  
    data () {
    return {
      user: null
    }
    
  },
  components: { FormUsers },
  methods: {
    ...mapActions('users', ['editUsers']),
    ...mapActions('users', ['getList']),
    submitRequested (user) {
      this.editUsers(user);
    },
  },
  mounted: function()
  {
    if(!this.$route.params.user)
    {
      router.push({ path: '/dashboard' });
    }
    else
    {
      this.user = this.$route.params.user;
    }
  }
}
</script> 
 
<style scoped>
</style> 