<template>
<div>
    <CCol sm="12">
        <CCard>
            <CCardBody class="d-flex flex-column">
                    <div role="group" class="form-group">
                        <!-- First Name -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('First Name') }}</label>
                            <input type="text" v-model="user.firstName" class="form-control" :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"/>
                            <div v-if="submitted && !$v.user.firstName.required" class="invalid-feedback">First Name is required</div>
                        </div>
                        <!-- Last Name -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Last Name') }}</label>
                            <input type="text" v-model="user.lastName" class="form-control" :class="{ 'is-invalid': submitted && $v.user.lastName.$error }"/>
                            <div v-if="submitted && !$v.user.lastName.required" class="invalid-feedback"> Last Name is required</div>
                        </div>
                        <!-- Phone Number -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Phone Number') }}</label>
                            <input type="text" v-model="user.phoneNumber" class="form-control" :class="{ 'is-invalid': submitted && $v.user.phoneNumber.$error }"/>
                            <div class="invalid-feedback" v-if="!$v.user.phoneNumber.numeric">Only numbers are allowed</div>
                            <div v-if="submitted && !$v.user.phoneNumber.required" class="invalid-feedback"> Phone Number is required</div>
                        </div>
                    </div>
                <!-- Action button + loading -->  
                <CSpinner v-if="loading" variant="success" small class="align-self-end"></CSpinner>
                <CButton v-else type="button" class="btn btn-primary align-self-end" @click="submitClicked">{{ ('Accept Changes') }}</CButton>
            </CCardBody>
        </CCard>
    </CCol>
    <ConfirmationPopUp :confirmationMessage="this.confirmationMessage" @closeConfirmRequested="onClickConfirmationPopup" v-model="showConfirmationPopup"></ConfirmationPopUp>
</div>
</template>
 
<script>

import { UTILS_HELPER } from "@/helpers/utils.js";
import { mapState } from 'vuex'
import ConfirmationPopUp from "@/views/controls/popups/ConfirmationPopUp.vue";
import router from '@/router/index';

   
import {
  required, 
  numeric
} from 'vuelidate/lib/validators'

  export default {
  components: { ConfirmationPopUp },
    props:{    
         baseUser:
        {
            type: Object,
            default: null,
        }
    },
    data()
    {
        return {
            submitted: false,
            user: {firstName:"",lastName:"",phoneNumber:""},
            loading: false,
            showConfirmationPopup: false,
            confirmationMessage:"",
    
        }
    },
        validations: {
                user: {
                    firstName: { required },
                    lastName: { required },
                    phoneNumber: { required, numeric },
                   
                },
            
        },
    mounted: function()
    {
        this.resetUser();
    },
    computed:
    {
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        errorOutput: function()
        {
            var result = [];

            if(this.alertType == 'alert-danger' && this.alertOutput && Array.isArray(this.alertOutput))
            {
                result = this.alertOutput.reduce(function(accum, currentVal) {
                accum[currentVal.key] = currentVal.value;
                    return accum;
                }, {});
            }

            return result;
        },

        ...mapState('users', ['processTask']),
    
  
    },
    methods:{
         resetUser(){

            if(this.baseUser){

                this.user = UTILS_HELPER.deepClone(this.user, this.baseUser);   
       
            }
            
        },
  
        submitClicked(){
            this.submitted = true;
            
             this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } 
            // Emit event
            if(this.user)
            {
                this.loading = true;
                this.$emit('submit-requested', this.user);
            }
        },
    
        onClickConfirmationPopup(value){
            this.showConfirmationPopup = value;     
            router.push('/dashboard');
        },

        errorValidator(fieldName)
        {
        var result = undefined;

        if(this.alertOutput && Array.isArray(this.alertOutput)) // Error alertOutput
        {
            if(this.alertOutput.find(obj => {
                return obj.key === fieldName
            }))
            {
                result = false;
            }
        }

        return result;
        },

    },
    watch:
    {
        processTask: function(val)
        {
            if(val)
            {
                this.showConfirmationPopup = true;
                this.confirmationMessage = "Data saved successfully!"
            }
        },

        baseUser: function()
        {
            this.resetUser();
        },
       
        alertType: function()
        {
            this.loading = false;
        },
        alertOutput: function()
        {
            this.loading = false;

            // Show pop up in case it is a string error
            // In case it is an array, it is shown on the field
            if(typeof this.alertOutput === 'string' || this.alertOutput instanceof String)
            {
                alert(this.alertOutput);
            }
        }
    }
  }
</script> 
 
<style scoped>

/deep/div.is-invalid > .base-image-input > span {
    background-color: palevioletred;
}

.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

    .showDropdown
    {
        display: block !important;
    }

    .input-group-append
    {
      height: fit-content;
    }

    img
    {
        width: 50%;
        height: 50%;
    }

</style> 